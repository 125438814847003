import React from 'react';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import { Grid, Row, Col, Panel, Tabs, Tab, MenuItem, SplitButton, Modal, Button } from 'react-bootstrap';
import ProfilePanel from '../Ui/ProfilePanel';
import dateFormat from 'dateformat';
import IconTooltip from '../Ui/IconTooltip';
import Swal from 'sweetalert2';
import Select from 'react-select';
import AuthService from '../../Services/AuthService';

const requestState = ['waiting', 'approved','reviewed', 'terminated', 'canceled', 'refunded', 'notOnline'];

class RequestDetails extends React.Component {
    constructor() {
        super();

        this.state = {
            request: null,
            logs: [],
            healthQuestionnary: [],
            charge: {},
            doctors: [],
            requestState: '',
            showModalEdit: false,
            selectedDoctor: {},
            showInputReason: false,
            reasonText: '',
            postQuestionary: []
        };

        this.Auth = new AuthService();

        this.handleRefund = this.handleRefund.bind(this);
        this.getRequest = this.getRequest.bind(this);
        this.handleDeleteInvoice = this.handleDeleteInvoice.bind(this)
    }

    getRequest() {
        fetchApi(`/requests/${this.props.match.params.id}`)
            .then(res => {
                let state = res.request.notOnlineReason && res.request.state === "waiting" ? 'notOnline' : res.request.state;

                this.setState({ request: { ...res.request, pname: res.pname }, requestState:  state});
            
                fetch('https://api.feeli.io/pathology/' + res.request.type)
                    .then(res => res.json())
                    .then(res => {
                        this.setState({
                            healthQuestionnary: res.healthQuestionary.sections
                        });
                        if (this.state.request.doctorId) {
                            fetchApi(
                                `/charges/retrieve/${this.state.request._id}`
                            )
                                .then(res => {
                                    this.setState({ charge: res.charge });
                                })
                                .catch(err => console.error(err.message));
                        }
                    });
                if (res.request.postQuestionary) {
                    fetch('https://api.feeli.io/pathology/post-teleconsultation')
                    .then(res => res.json())
                    .then(res => {
                        this.setState({
                            postQuestionary: res.healthQuestionary.sections
                        });
                    })
                    .catch(err =>
                        notif.addNotification(
                            'Erreur',
                            'Erreur de récupération des données post suivi téléconsultation: ' + err,
                            'warning'
                        )
                    );

                }
            })
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getRequest();

        fetchApi(`/logs/request/${this.props.match.params.id}`)
            .then(res => {
                this.setState({ logs: res.logs });
            })
            .catch(err => console.error(err));

        fetchApi('/doctors?activation=true&limit=500')
            .then(res => this.setState({ doctors: res.doctors }))
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );


    }

    
    getResponseLine() {
        if (this.state.request.skycallQuestion !== null) {
            return Object.keys(this.state.request.skycallQuestion).map((v) => (
                <tr key={v}>
                    <td>
                        <p>{v}</p>
                        <p><strong>{this.state.request.skycallQuestion[v]}</strong></p>
                    </td>
                </tr>
            ))
        }

        if (Array.isArray(this.state.request.externalQuestion) &&  this.state.request.externalQuestion.length > 0) {
            return this.state.request.externalQuestion.map((v) => (
                <>
                    {!!v.name && (
                        <tr key={v.name}>
                            <td>
                                <p>{v.name}</p>
                                <p><strong>{v.response}</strong></p>
                            </td>
                        </tr>
                    )}
                    {v.precisions && v.precisions.length > 0 && v.precisions.filter((k) => !!k.name && !!k.value).map((k) => (
                        <tr key={k.name}>
                            <td>
                                <p>{k.name}</p>
                                <p><strong>{k.value}</strong></p>
                            </td>
                        </tr>
                    ))}
                </>

            ))
        }

        const questions = [];

        this.state.healthQuestionnary.forEach(s => {
            s.data.forEach(d => {
                if (this.state.request.decisionTree && d.condition) {                      
                    let decisions = null;

                    if (this.state.request.decisionTree.questionnaire) {
                        decisions = this.state.request.decisionTree
                            .questionnaire.decisions;
                    } else {
                        decisions = this.state.request.decisionTree;
                    }
                    
                    let field;
                    if (d.condition.indexOf('==') > -1) {
                        field = decisions[d.condition.split('==')[0].trim()];

                        if (
                            field !== undefined &&
                            field.toString() ===
                                d.condition
                                    .split('==')[1]
                                    .trim()
                                    .toString()
                        ) {
                            questions.push(d);
                        }
                    } else if (d.condition.indexOf('!=') > -1) {
                        field = decisions[d.condition.split('!=')[0].trim()];
                        
                        if (
                            field !== undefined &&
                            field.toString() !==
                                d.condition
                                    .split('!=')[1]
                                    .trim()
                                    .toString()
                        ) {
                            questions.push(d);
                        }
                    } else if (d.condition.indexOf('#include') > -1) {
                        field = decisions[d.condition.split('#include')[0].trim()];
                        
                        if (
                            field !== undefined &&
                            !field.includes( d.condition.split('#include')[1].trim())
                        ) {
                            questions.push(d);
                        }
                    } 
                } else {
                    questions.push(d);
                }
            });
        });
    

        return questions.map((q) => {

            let decision = null;
    
            if (
                this.state.request.decisionTree
                    .questionnaire
            ) {
                decision = this.state.request
                    .decisionTree.questionnaire
                    .decisions[q.name];
            } else {
                decision = this.state.request
                    .decisionTree[q.name];
            }

            let out = null;


            if (q.type === 'bool') {
                if (q.name === 'questionSex') {
                    out = decision ? 'Garçon' : 'Fille';
                } else if (q.name === 'questionProfile') {
                    out = decision ? 'Pour vous' : 'Pour un proche';
                }else {
                    out = decision ? 'Oui' : 'Non';
                }
            } else if (q.type === 'choice') {
                const cont = q.options.find(
                    ({ value }) => value === decision
                );
                out = cont && cont.content;
            } else if (q.type === 'select') {
                out = decision;
            } else if (q.type === 'multiple') {
                out = q.options
                    .filter(({ value }) =>
                        typeof decision === 'number'
                            ? decision === value
                            : decision.indexOf(
                                value
                            ) !== -1
                    )
                    .map(({ content }) => (
                        <span
                        style={{ display: 'block' }}
                        key={content}
                    >
                        &bull; {content}
                    </span>
                ));
            } else if (q.type === 'input') {
                out = decision;
            } else if (q.type === 'text') {
                out = decision;
            } else if (q.type === 'file') {
                out = decision;
            }
            return (
                <tr key={q.name}>
                    <td>
                        <td>{q.label}</td>
                        {
                            q.type === 'file' && out ?
                                out.map((el, i) => el && 
                                <td key={i}
                                    style= {{cursor: 'pointer', color: '#337ab7'}}>
                                    {el.name}
                                </td>) : (
                                    <strong> {out}</strong>
                                )
                        }
                    </td>
                </tr>
            );
        })



    }

    getResponsePostSuivi() {
        const postQuestions= [];
        this.state.postQuestionary && this.state.postQuestionary.forEach(s => {
            s.data.forEach(d => {
                    if (this.state.request.postQuestionary && d.condition) {                      
                        let decisions = this.state.request.postQuestionary;
                    
                        let field;
                        if (d.condition.indexOf('==') > -1) {
                            field = decisions[d.condition.split('==')[0].trim()];

                            if (
                                field !== undefined &&
                                field.toString() ===
                                    d.condition
                                        .split('==')[1]
                                        .trim()
                                        .toString()
                            ) {
                                postQuestions.push(d);
                            }
                        } else if (d.condition.indexOf('!=') > -1) {
                            field = decisions[d.condition.split('!=')[0].trim()];
                        
                            if (
                                field !== undefined &&
                                field.toString() !==
                                    d.condition
                                        .split('!=')[1]
                                        .trim()
                                        .toString()
                            ) {
                                postQuestions.push(d);
                            }
                        } else if (d.condition.indexOf('#include') > -1) {
                            field = decisions[d.condition.split('#include')[0].trim()];
                        
                            if (
                                field !== undefined &&
                                !field.includes( d.condition.split('#include')[1].trim())
                            ) {
                                postQuestions.push(d);
                            }
                        } 
                    } else {
                        postQuestions.push(d);
                    }
                
                
            });
        });

        return postQuestions.map(q => {
            let decision = this.state.request.postQuestionary[q.name];

            let out = null;

            if (q.type === 'bool') {    
                out = decision ? 'Oui' : 'Non';    
            } else if (q.type === 'choice') {
                const cont = q.options.find(
                    ({ value }) => value === decision
                );
                out = cont && cont.content;
            } else if (q.type === 'select') {
                out = decision;
            } else if (q.type === 'multiple') {
                out = q.options
                    .filter(({ value }) =>
                        typeof decision === 'number'
                            ? decision === value
                            : decision.indexOf(
                                value
                            ) !== -1
                    )
                    .map(({ content }) => (
                        <span
                            style={{ display: 'block' }}
                            key={content}
                        >
                            &bull; {content}
                        </span>
                    ));
            } else if (q.type === 'input') {
                out = decision;
            } else if (q.type === 'text') {
                out = decision;
            } else if (q.type === 'file') {
                out = decision;
            }
            return (
                <tr key={q.name}>
                    <td>
                            <p>{q.label}</p>
                        {
                            q.type === 'file' && out ?
                                out.map((el, i) => el && 
                                <p key= {i} onClick= {() => this.setState({ showPhotoModal: true, photoPath: el.path})}
                                    style= {{cursor: 'pointer', color: '#337ab7'}}>
                                    {el.name}
                                </p>) : (
                                    <p>
                                        <strong>{out}</strong>
                                    </p>
                                )
                        }
                    </td>
                </tr>
            );
        })

    }
    
    handleStateEdit(state) {
        if (state === "notOnline") {
            this.setState({ showInputReason : true })
        } else {
            this.setState({ requestState: state });

            fetchApi(`/requests/${this.state.request._id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    state,
                    ...(state === 'waiting' && { doctorId: null })
                })
            }).then(() => {
                if (state === 'waiting') {
                    this.setState({ request: { ...this.state.request, doctorId: null } });
                }
                notif.addNotification('Status changé', 'Modification réussi du status !');
            }).catch(() => {
                notif.addNotification('Status non changé', 'La modification du status a échoué !');
            });
        }
    }

    showModalEdit() {
        this.setState({ showEditModal: true });
    }

    handleDeleteInvoice() {
        fetchApi(`/requests/${this.state.request._id}/deleteInvoice`, {
            method: 'PUT',
        }).then(() => {
            this.getRequest();
            notif.addNotification('Facture supprime !');
        }).catch(() => {
            notif.addNotification('Erreur, facture non supprime');
        });
    }

    handleCancel() {
        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Annulation d\'une demande',
            text: `Voulez vous vraiment annuler cette requete ? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, modifier',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            fetchApi(`/requests/${this.state.request._id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    state: 'canceled'
                })
            }).then(() => {
                let newRequest = this.state.request;
                newRequest.state = 'canceled';
                this.setState({
                    request: newRequest
                });

                swalWithBootstrapButtons(
                    'Modifié!',
                    'Requete annulé',
                    'success'
                );
            });
        });
    }

    handleRefund() {
        fetchApi(`/charges/refunds/${this.state.request._id}`, {
            body: JSON.stringify({ uemail: this.state.request.userId.email, name: `${this.state.request.userId.FName} ${this.state.request.userId.LName}` }),
            method: 'PUT'
        })
            .then(() => {
                const items = this.state.request;
                items.state = 'refunded';
                this.setState({ request: items });

                notif.addNotification(
                    'Derniere connextion',
                    'Remboursement bien effectué !'
                );
            })
            .catch(err => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de remboursement : ' + err,
                    'warning'
                );
            });
    }

    updateDoctor() {
        fetchApi(`/requests/${this.state.request._id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    doctorId: this.state.selectedDoctor.value
                })
            }).then(() => {
                this.setState({ showEditModal: false, selectedDoctor: {} }, () => {
                    this.getRequest();
                })
            });
    }

    editDoctorModal() {
        return (
            <Modal show={this.state.showEditModal}>
                <Modal.Header>
                    <Modal.Title>Selectionner un docteur</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Select
                        value={this.state.selectedDoctor}
                        onChange={(v) => this.setState({ selectedDoctor: v })}
                        options={this.state.doctors.map((v) => ({ value: v._id, label: `Dr. ${v.FName} ${v.LName}` }))}
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        placeholder="Choisir :" 
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ showEditModal: false, selectedDoctor: {} })}>Close</Button>
                    <Button onClick={() => this.updateDoctor()} bsStyle="primary">Valider</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    setNotOnline() {
        if (window.confirm('Vous allez informer le patient que son demande ne sera pas traite a distance , Continuer ?')) {
            fetchApi(`/requests/setNotOnline/${this.state.request._id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    reasonText: this.state.reasonText,
                    state: 'waiting'
                })
            }).then((response) => {
                notif.addNotification('Status changé', 'Modification réussi du status !');
                this.setState({requestState: 'notOnline'})
            }).catch(() => {
                notif.addNotification('Status non changé', 'La modification du status a échoué !');
            });
       }
   }

    reasonModal() {
        return (
            <Modal show={this.state.showInputReason}>
                <Modal.Header>
                    <Modal.Title>Saisir la raison</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <textarea style={{ width: '100%'}} type="text" value={this.state.reasonText} onChange={(e) => this.setState({ reasonText: e.target.value })} />
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ showInputReason: false, reasonText: '' })}>Close</Button>
                    <Button onClick={() => this.setState({ showInputReason: false }, () => this.setNotOnline())} bsStyle="primary">Envoyer</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    
    render() {
        if (!this.state.request) {
            return <div>Loading...</div>;
        }

        if (!this.state.request.userId) {
            return <p>Utlisateur supprimé</p>
        }

        const request = this.state.request;

        let attachments = [...(request.attachments ? [...request.attachments] : []) , ...(request.prescription ? [...request.prescription] : [])];

        let acceptedTime = null;
        if (request.acceptedAt) {
            acceptedTime =
                new Date(request.acceptedAt) - new Date(request.createdAt);

            let seconds = Math.floor(acceptedTime / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);

            acceptedTime = `${days} jours, ${hours % 24} heures, ${minutes %
                60} minutes et ${seconds % 60} secondes`;
        }
        let sendPrescriptionTime = null; 
        let fistPrescription = attachments.find(el => el.name.includes("ordonnance") || el.name.includes("presc"))
    
        if (fistPrescription && request.acceptedAt) {
            sendPrescriptionTime =
                new Date(fistPrescription.createdAt) - new Date(request.acceptedAt);
            if ( sendPrescriptionTime >= 0 ) {
                let seconds = Math.floor(sendPrescriptionTime / 1000);
                let minutes = Math.floor(seconds / 60);
                let hours = Math.floor(minutes / 60);
                let days = Math.floor(hours / 24);
    
                sendPrescriptionTime = `${days} jours, ${hours % 24} heures, ${minutes %
                    60} minutes et ${seconds % 60} secondes`;
            } else {
                sendPrescriptionTime = "erreur"
            }
        }

        let responsesTime = null; 
        if (request.messenger.length > 1) {
            let fromUser1 = [...request.messenger].reverse().find(el => el.doctorId!== null);
            let fromUser2 = [...request.messenger].reverse().find(el => el.userId!== null);

            if (fromUser1 && fromUser2) {
                responsesTime = Math.abs(new Date(fromUser1.createdAt) - new Date(fromUser2.createdAt));

                let seconds = Math.floor(responsesTime / 1000);
                let minutes = Math.floor(seconds / 60);
                let hours = Math.floor(minutes / 60);
                let days = Math.floor(hours / 24);

                responsesTime = `${days} jours, ${hours % 24} heures, ${minutes %
                    60} minutes et ${seconds % 60} secondes`;
            }
        }

        const profile = this.Auth.getProfile();
        const isAdminFiscale = profile.role === 5; 

        return (
            <div className="pT-25">
                <Row>
                    <Col lg={3} xs={12}>
                        <button
                            onClick={() => this.props.history.goBack()}
                            className="backBtn"
                        >
                            <i className="icon-arrow-left" />
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} xs={12}>
                        <h2>Patient</h2>
                        <ProfilePanel isRequest={isAdminFiscale ? false : true} {...request.userId}>
                            <Grid fluid={true}>
                                <Row>
                                    <Col md={12}>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>Sexe</td>
                                                    <td>
                                                        {request.userId.gender}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Age</td>
                                                    <td>
                                                        {!request.userId
                                                            .birth_date ||
                                                            new Date(
                                                                new Date() -
                                                                    new Date(
                                                                        request.userId.birth_date
                                                                    )
                                                            ).getFullYear() -
                                                                1970}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Tel</td>
                                                    <td>
                                                        {request.userId.phone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td> 
                                                        <span style={{ fontSize: 12}}>{request.userId.email}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Grid>
                        </ProfilePanel>
                    </Col>
                    <Col lg={9} xs={12}>
                        <h2>Requête {request.priority && <span className='priority-badge'>Urgent</span>}</h2>
                        <Panel>
                            <Panel.Body style={{ paddingTop: 0 }}>
                                <Tabs
                                    defaultActiveKey={1}
                                    className="custom"
                                    id="tabs"
                                >
                                    <Tab eventKey={1} title="Détails">
                                        <Row>
                                            <Col md={12}>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Date de creation
                                                            </td>
                                                            <td>
                                                                {dateFormat(
                                                                    request.createdAt,
                                                                    'dd/mm/yy HH:MM'
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Derniere mise a
                                                                jour
                                                            </td>
                                                            <td>
                                                                {dateFormat(
                                                                    request.updatedAt,
                                                                    'dd/mm/yy HH:MM'
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Type</td>
                                                            <td>
                                                                {request.type}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Statut</td>
                                                            <td>
                                                                {request.state === "waiting" && request.notOnlineReason ? "Impossible à distance" : request.state}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Docteur</td>
                                                            <td>
                                                                {!request.doctorId || (
                                                                    <p>
                                                                        {
                                                                            request
                                                                                .doctorId
                                                                                .FName
                                                                        }{' '}
                                                                        {
                                                                            request
                                                                                .doctorId
                                                                                .LName
                                                                        }
                                                                    </p>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Temps
                                                                d'acceptation
                                                            </td>
                                                            <td>
                                                                {acceptedTime}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Temps
                                                                d'envoi de l'ordonnance (après avoir approuvé)
                                                            </td>
                                                            <td>
                                                                {sendPrescriptionTime}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Nombre
                                                                d'echanges
                                                            </td>
                                                            <td>
                                                                {
                                                                    request
                                                                        .messenger
                                                                        .length
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Temps entre les derniers exchanges
                                                            </td>
                                                            <td>
                                                                {responsesTime}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                               gclid
                                                            </td>
                                                            <td>
                                                                {request.gclid}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                               utm_source
                                                            </td>
                                                            <td>
                                                                {request.utm_source}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                               utm_pharmacy
                                                            </td>
                                                            <td>
                                                                {request.utm_pharmacy}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                               pharmacy_name
                                                            </td>
                                                            <td>
                                                                {request.pname}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                               utm_content
                                                            </td>
                                                            <td>
                                                                {request.utm_content}
                                                            </td>
                                                        </tr>
                                                        {request.notOnlineReason && (
                                                            <tr>
                                                                <td>
                                                                    Non faisable parceque :
                                                                </td>
                                                                <td>
                                                                    {request.notOnlineReason}
                                                                </td>
                                                            </tr>
                                                        ) }
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    {
                                        !isAdminFiscale &&
                                        <Tab eventKey={2} title="Questionnaire">
                                            <Row>
                                                <Col md={12}>
                                                    <table className="table">
                                                        <tbody>
                                                            {this.getResponseLine()}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    }
                                     {
                                        !isAdminFiscale && request.postQuestionary &&
                                        <Tab eventKey={3} title="Suivi post téléconsultation">
                                            <Row>
                                                <Col md={12}>
                                                    <table className="table">
                                                        <tbody>
                                                            {this.getResponsePostSuivi()}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    }
                                    {
                                         !isAdminFiscale &&
                                         <Tab eventKey={4} title="Fichiers">
                                         <Row>
                                             <Col md={12}>
                                                 <table className="table">
                                                     <tbody>
                                                         {attachments.length > 0 ? (
                                                             <>
                                                                 {attachments.filter(Boolean).map(
                                                                     (
                                                                         element,
                                                                         index
                                                                     ) =>
                                                                         (
                                                                         <>
                                                                             <tr
                                                                                 key={
                                                                                     element.name
                                                                                 }
                                                                             >
                                                                                 <td>
                                                                                     {
                                                                                         element.type
                                                                                     }
                                                                                 </td>
                                                                                 <td>
                                                                                     <a
                                                                                         href={
                                                                                             element.path
                                                                                         }
                                                                                         target="_blank"
                                                                                     >
                                                                                         {
                                                                                             element.name
                                                                                         }
                                                                                     </a>
                                                                                     {(element.used && element.used.by) && ` (${element.used.by})`}
                                                                                 </td>
                                                                             </tr>
                                                                         </>
                                                                     )
                                                                 )}
                                                                 {
                                                                     request.invoice && (
                                                                             <>
                                                                             <tr
                                                                                 key={
                                                                                     request.invoice
                                                                                 }
                                                                             >
                                                                                 <td>
                                                                                     {
                                                                                         request.invoice.split('.')[5]
                                                                                     }
                                                                                 </td>
                                                                                 <td>
                                                                                     <a
                                                                                         href={
                                                                                             request.invoice
                                                                                         }
                                                                                         target="_blank"
                                                                                     >
                                                                                         {
                                                                                             request.invoice
                                                                                         }
                                                                                     </a>
                                                                                 </td>
                                                                             </tr>
                                                                             <button 
                                                                                 style={{
                                                                                     border: 'none',
                                                                                     color: 'red'
                                                                                 }}
                                                                                 onClick={this.handleDeleteInvoice}
                                                                             >
                                                                                 Suppimer la facture
                                                                             </button>
                                                                             </>
                                                                         )
                                                                 }
                                                                 
                                                             </>
                                                         ) : (
                                                                 <tr>
                                                                     <td>
                                                                         Il n'y a
                                                                         pas de
                                                                         fichiers
                                                                         lié à
                                                                         cette
                                                                         requete
                                                                     </td>
                                                                 </tr>
                                                             )
                                                         }
                                                     </tbody>
                                                 </table>
                                             </Col>
                                         </Row>
                                     </Tab>
                                    }
                                    

                                    <Tab eventKey={5} title="Paiement">
                                        <Row>
                                            <Col md={12}>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Status</td>
                                                            <td>
                                                                {(() => {
                                                                    if (
                                                                        this
                                                                            .state
                                                                            .request
                                                                            .state === 'refunded'
                                                                    ) {
                                                                        return 'Remboursé';
                                                                    }
                                                                    
                                                                    if (
                                                                        this
                                                                        .state
                                                                        .request
                                                                        .state === 'approved' ||
                                                                        this
                                                                        .state
                                                                        .request
                                                                        .state === 'reviewed' || 
                                                                        this
                                                                        .state
                                                                        .request
                                                                        .state === 'terminated' ||
                                                                        this
                                                                        .state
                                                                        .request
                                                                        .canceledByDoctor
                                                                    ) {
                                                                        return 'Payé';
                                                                    }

                                                                    return 'Non Payé';
                                                                })()}
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>Montant</td>
                                                            <td>
                                                                {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.charge.amount / 100)}
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td>
                                                                Email de
                                                                facturation
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .charge
                                                                        .receipt_email
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Facture n°</td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .charge
                                                                        .receipt_number
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            className={
                                                                this.state
                                                                    .charge
                                                                    .dispute
                                                                    ? ''
                                                                    : 'hidden'
                                                            }
                                                        >
                                                            <td>
                                                                Opposition
                                                                Bancaire
                                                            </td>
                                                            <td>
                                                                L'utilisateur a
                                                                fait opposition
                                                                sur le paiement
                                                            </td>
                                                        </tr>
                                                        {
                                                            !isAdminFiscale && 
                                                            <tr>
                                                                <td>
                                                                    Remboursement
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className={
                                                                            this.state.request.state === 'refunded' ? 'user-lock lock disable' : 'user-lock lock'
                                                                        }
                                                                        disabled={this.state.request.state === 'refunded'}
                                                                        onClick={() => {
                                                                            if (
                                                                                window.confirm(
                                                                                    'Êtes-vous sûr de vouloir rembourser cet utilisateur ?'
                                                                                )
                                                                            )
                                                                                this.handleRefund();
                                                                        }}
                                                                    >
                                                                        Rembourser
                                                                        l'utilisateur
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={6} title="Historique">
                                        <Row>
                                            <Col md={12}>
                                                <table className="table">
                                                    <tbody>
                                                        {(() => {
                                                            return this.state.logs.map(
                                                                (
                                                                    log,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <p
                                                                            key={index}
                                                                            className={
                                                                                'log ' +
                                                                                log.type.toLowerCase()
                                                                            }
                                                                        >
                                                                            <span className="log-date">
                                                                                {dateFormat(
                                                                                    log.date,
                                                                                    'dd/mm/yy HH:MM'
                                                                                )}
                                                                            </span>{' '}
                                                                            | 
                                                                            {
                                                                                log.message
                                                                            }
                                                                        </p>
                                                                    );
                                                                }
                                                            );
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    {
                                        !isAdminFiscale && 
                                        <Tab eventKey={7} title="Modification">
                                            <Row>
                                                <Col md={12}>
                                                    <div className="update-state">
                                                        <p>Changer le status :</p>
                                                        <SplitButton
                                                            id="state-list" 
                                                            title={this.state.requestState}
                                                            rootCloseEvent="mousedown"
                                                        >
                                                            {requestState.map((v, i) => (
                                                                <MenuItem
                                                                    eventKey={v}
                                                                    active={v === this.state.requestState}
                                                                    onSelect={v === this.state.requestState ? undefined : (e) => this.handleStateEdit(e)}
                                                                    key={`item-${i}`}
                                                                >
                                                                    {v}
                                                                </MenuItem>
                                                            ))}
                                                        </SplitButton>
                                                    </div>
                                                    <table style={{ marginTop: '2rem' }} className="table">
                                                        {this.editDoctorModal()}
                                                        {this.reasonModal()}
                                                        <tbody>
                                                            <tr>
                                                                <td>Médecin attribué</td>
                                                                <td>{ request.doctorId ? `Dr. ${request.doctorId.LName} ${request.doctorId.FName} ` : 'Aucun'} 
                                                                    &nbsp;&nbsp;<IconTooltip
                                                                        icon="fas fa-edit"
                                                                        text="Modifier le médecin"
                                                                        onClick={() =>
                                                                            this.setState({ selectedDoctor: request.doctorId ? { value: request.doctorId._id, label: `Dr. ${request.doctorId.FName} ${request.doctorId.LName}` } : {} }, () => {
                                                                                this.showModalEdit()
                                                                            })
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <button
                                                        className={
                                                            this
                                                                .state
                                                                .request
                                                                .state === 'approved' || 
                                                                this
                                                                    .state
                                                                    .request
                                                                    .state === 'canceled'
                                                                ? 'user-lock lock disable'
                                                                : 'user-lock lock'
                                                        }
                                                        disabled={
                                                            this
                                                                .state
                                                                .request
                                                                .state === 'approved' || 
                                                                this
                                                                    .state
                                                                    .request
                                                                    .state === 'canceled'

                                                        }
                                                        onClick={() => {
                                                            this.handleCancel();
                                                        }}
                                                    >
                                                                        Annuler la demande
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    }
                                </Tabs>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default RequestDetails;
