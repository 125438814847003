import React from 'react';
import { Row, Col, Panel } from 'react-bootstrap';
import ReactEcharts from 'echarts-for-react';

import { fetchApi } from '../../Network/NetworkService';

import { notif } from '../Ui/Notification';
import { CardCount } from '../Ui/Card';
import PartnerDashBoard from '../Ui/PartnerDashboard';
import WeeklyCounter from '../Ui/WeeklyCounter';
import isoDate from '../../Services/toIsoDate.js';

import '../../Assets/Ui/Dashboard.css';


const colors = {
    user: {
        main: '#8051A2',
        areaColor: '#8051A245'
    },
    request: {
        main: '#02ce26',
        areaColor: '#0084166b'
    },
    turnover: {
        main: '#D6AE71',
        areaColor: '#D6AE7150'
    },
    visit: {
        main: '#00cdac',
        areaColor: '#3ae0c4c9'
    }
};

const lineOptions = {
    tooltip: {
        trigger: 'axis'
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: false
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#878787'
                }
            },
            splitLine: {
                show: false
            },
            data: []
        }
    ],
    yAxis: [
        {
            type: 'value',
            axisLine: {
                show: false
            },
            splitLine: {
                show: false
            }
        }
    ],
    series: [
        {
            type: 'line',
            data: [],
            smooth: true,
            itemStyle: {
                normal: {
                    color: "#dedede"
                }
            },
            areaStyle: {
                color: "#dedede"
            }
        }
    ]
};

export default class Dashboard extends React.Component {
    constructor() {
        super();

        this.state = {
            usersCount: null,
            requests: [],
            userStats: [],
            countRequest: 0,
            doctorPercent: {},
            requestOfToday: 0,
            userOfToday: 0,
            visitOfToday: 0,
            requestOfYesterday: 0,
            userOfYesterday: 0,
            visitOfYesterday: 0,
            visitsCount: 0,
            visitStats: [],
            requestsCA: [],
            turnoverOfToday: 0,
            turnoverOfYesterday: 0,
            countWaiting: 0,
            requestsOfTodayPartner: [],
            times: [],
            range: [new Date().setDate(new Date().getDate()-1), new Date().setDate(new Date().getDate()-1)],
            date: new Date().toISOString().split('T')[0]
        };

        this.getUsersOptions = this.getUsersOptions.bind(this);
        this.getRequestsOptions = this.getRequestsOptions.bind(this);
        this.getVisitOptions = this.getVisitOptions.bind(this);
        this.getRepOptions = this.getRepOptions.bind(this);
        this.getCAOptions = this.getCAOptions.bind(this);
        this.getDoctorPercent = this.getDoctorPercent.bind(this)
    }

  
    getUsersOptions() {

        if (!this.state.userStats) {
            return this.stats;
        }

        const options = lineOptions;
        const xAxisLegend = [
            ...new Set([
                ...this.state.userStats.map(
                    ({ _id }) => _id.month.toString() + '/' + _id.year.toString()
                )
            ])
        ];
        const xAxisDataUsrs = [];

        for (let i = 0; i < xAxisLegend.length; i++) {
            const year = parseInt(xAxisLegend[i].split('/')[1]);
            const month = parseInt(xAxisLegend[i].split('/')[0]);

            const usersRegistered = this.state.userStats.find(
                ({ _id }) => _id.year === year && _id.month === month
            );

            if (!usersRegistered) {
                xAxisDataUsrs.push(0);
            } else {
                xAxisDataUsrs.push(usersRegistered.count);
            }
        }

        options.xAxis[0].data = xAxisLegend;
        options.series[0].data = xAxisDataUsrs;
        options.series[0].itemStyle.normal.color = colors.user.main;
        options.series[0].areaStyle.color = colors.user.areaColor;

        return JSON.parse(JSON.stringify(options));
    }

    getRequestsOptions() {

        if (!this.state.requests) {
            return;
        }

        const options = lineOptions;
        const xAxisLegend = [
            ...new Set([
                ...this.state.requests.map(
                    ({ _id }) => _id.month?.toString() + '/' + _id.year?.toString()
                )
            ])
        ];
        const xAxisDataRequest = {};
        
        this.state.requests.map(el => {
            
            if (!xAxisDataRequest[`${el._id.month}/${el._id.year}`]) {
                xAxisDataRequest[`${el._id.month}/${el._id.year}`] = el.count;
            } else {
                xAxisDataRequest[`${el._id.month}/${el._id.year}`] += el.count;
            }
        })

        options.xAxis[0].data = xAxisLegend;
        options.series[0].data = Object.keys(xAxisDataRequest).map(el => xAxisDataRequest[el]);
        options.series[0].itemStyle.normal.color = colors.request.main;
        options.series[0].areaStyle.color = colors.request.areaColor;

        return JSON.parse(JSON.stringify(options));
    }

    getVisitOptions() {

        const options = lineOptions;

        if (!this.state.visitStats) {
            return options;
        }
        
        const xAxisLegend = [
            ...new Set([
                ...this.state.visitStats.map(
                    el => el.date
                )
            ])
        ];
        const xAxisDataVisits = this.state.visitStats.map(el => el.count);

        options.xAxis[0].data = xAxisLegend;
        options.series[0].data = xAxisDataVisits;
        options.series[0].itemStyle.normal.color = colors.visit.main;
        options.series[0].areaStyle.color = colors.visit.areaColor;

        return JSON.parse(JSON.stringify(options));
    }

    getRepOptions() {
        if (!this.state.requests) {
            return;
        }
        const options = {
            title: {
              text: 'Répartition',
              left: 'left'
            },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '',
                type: 'pie',
                radius: '50%',
                data: [],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };

        const datas = {};
        this.state.requests.map(el => {
            
            if (!datas[el._id.type]) {
                datas[el._id.type] = {name: el._id.type, value: el.count};
            } else {
                datas[el._id.type].value += el.count;
            }
        })

        options.series[0].data = Object.keys(datas).map(el => datas[el]).filter(element=>element.value > 500);
        return JSON.parse(JSON.stringify(options));
    }

    getCAOptions() {
        const options = {
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: false
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: []
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            series: [
                {
                    name: "Chiffre d'affaire",
                    type: 'line',
                    smooth: true,
                    data: [],
                    itemStyle: {
                        color: colors.turnover.main
                    },
                    areaStyle: {
                        color: colors.turnover.areaColor
                    }
                }
            ]
        };

        const xAxisLegend = [
            ...new Set([
                ...this.state.requestsCA.map(
                    ({ _id }) => _id.month.toString() + '/' + _id.year.toString()
                )
            ])
        ];

        const xAxisData = [];

        for (let i = 0; i < xAxisLegend.length; i++) {
            const year = parseInt(xAxisLegend[i].split('/')[1]);
            const month = parseInt(xAxisLegend[i].split('/')[0]);

            const reqs = this.state.requestsCA.filter(
                ({ _id }) => _id.year === year && _id.month === month
            );
          
            if (!reqs) {
                xAxisData.push(0);
            } else {
                xAxisData.push(reqs.map((v) => v.type === 'dermatologue' ? v.count*45 : v.count*30).reduce((a, b) => a + b, 0));
            }
        }

        options.xAxis.data = xAxisLegend;
        options.series[0].data = xAxisData;

        return options;
    }

    getDoctorPercent() {
            if (!this.state.doctorPercent) {
                return;
            }
        
            const options = {
                title: {
                    text: 'Docteur par pathologie'
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    }
                ],
                series: [
                    {
                        type: 'bar',
                        data: [],
                        barWidth: '60%',
                    }
                ]
            };
    
            options.xAxis[0].data = Object.keys(this.state.doctorPercent);
            options.series[0].data = Object.keys(this.state.doctorPercent).map(el => this.state.doctorPercent[el]);
           
            return JSON.parse(JSON.stringify(options));
    }

    getTimes() { 
        const options = {
            title: {
                text: ''
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisTick: {
                        alignWithLabel: true
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}'
                    }
                }
            ],
            series: [
                {
                    type: 'bar',
                    data: [],
                    barWidth: '60%',
                }
            ]
        };


        const xAxisLegend = [
            ...new Set([
                ...this.state.times.map(
                    ({ _id }) => _id.hour.toString() + 'h'
                )
            ])
        ];

        const xAxisData = [
                ...this.state.times.map(
                    (el) =>{ 
                        let color = el._id.hour >6 && el._id.hour < 18 ? "#69cfd3" : "#96edf0b6"
                        return {value: el.count, itemStyle: {color: color}}
                    }
                )
        ];

        options.xAxis[0].data = xAxisLegend;
        options.series[0].data = xAxisData;
       
        return JSON.parse(JSON.stringify(options));
}

    componentDidMount() {
        fetchApi('/doctors/pathology-percentage')
            .then((res) => this.setState({ doctorPercent: res.datas }))
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees user: ' + err,
                    'warning'
                )
            );

        fetchApi('/users/count')
            .then(({ count }) => this.setState({ usersCount: count }))
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees user: ' + err,
                    'warning'
                )
            );

        fetchApi(`/requests/reqStat${this.props.role === 4 ? `?utm_source=${this.props.utm_source}` : ''}`)
            .then(({ requests }) => {
                this.setState({ requests: requests })
            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );

        fetchApi(`/requests/stats`)
            .then(({ cAllReq, countWaiting }) =>
                this.setState({
                    countRequest: cAllReq,
                    countWaiting
                })
            )
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );

        fetchApi('/users/stats')
            .then(({ stats }) => this.setState({ userStats: stats }))
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );
        
        fetchApi(`/requests/count`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { start: isoDate(null, 0, 0) } ),
                ...( { end: isoDate(null, 23, 59) } ),
                ...( { state: 'approved,reviewed,terminated,notOnline,waiting,toCall,refunded,canceled' } ),
                ...(  this.props.utm_source ? { utm_source: this.props.utm_source } : {utm_source: ''})
            })
        })
            .then((res) => {
                this.setState({requestOfToday: res.count});
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            });
        
        fetchApi(`/requests/count`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { start: isoDate(new Date(new Date().setDate(new Date().getDate()-1)), 0, 0) } ),
                ...( { end: isoDate(new Date(new Date().setDate(new Date().getDate()-1)), 23, 59) } ),
                ...( { state: 'approved,reviewed,terminated,notOnline,waiting,toCall,refunded,canceled' } ),
                ...( this.props.utm_source ? { utm_source: this.props.utm_source } : {utm_source: ''})
            })
        })
            .then((res) => {
                this.setState({requestOfYesterday: res.count});
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            });

        fetchApi(`/users/count`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { start: isoDate(null, 0, 0) } ),
                ...( { end: isoDate(null, 23, 59) } ),
            })
        })
            .then((res) => {
                this.setState({userOfToday: res.count});
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees utilisateurs: ' + err,
                    'warning'
                )
            });

        fetchApi(`/users/count`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { start: isoDate(new Date(new Date().setDate(new Date().getDate()-1)), 0, 0) } ),
                ...( { end: isoDate(new Date(new Date().setDate(new Date().getDate()-1)), 23, 59) } ),
            })
        })
            .then((res) => {
                this.setState({userOfYesterday: res.count});
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees utilisateurs: ' + err,
                    'warning'
                )
            });

        fetchApi(`/pageView/count/${new Date().toISOString().split('T')[0]}`)
            .then((res) => {
                this.setState({visitOfToday: res.count});
            })
            .catch((err) => console.log(err));
        
        fetchApi(`/pageView/count/${new Date(new Date().setDate(new Date().getDate()-1)).toISOString().split('T')[0]}`)
            .then((res) => {
                this.setState({visitOfYesterday: res.count});
            })
            .catch((err) => console.log(err));

        fetchApi(`/pageView/count`)
            .then((res) => {
                this.setState({visitsCount: res.count});
            })
            .catch((err) => console.log(err));
        
        fetchApi('/pageView/stats')
            .then(res =>  this.setState({ visitStats: res.stats }))
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );

        fetchApi(`/requests/reqStat?state=approved,reviewed,terminated${this.props.role === 4 ? `&utm_source=${this.props.utm_source}` : ''}`)
            .then(({ requests }) => {
                this.setState({ requestsCA: requests })
            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );
        fetchApi(`/requests/reqStat?state=approved,reviewed,terminated&start=${isoDate(null, 0, 0)}&end=${isoDate(null, 23, 59)}${this.props.role === 4 ? `&utm_source=${this.props.utm_source}` : ''}`)
            .then(({ requests }) => {
                this.setState({ turnoverOfToday:  requests.map((v) => v.type === 'dermatologue' ? v.count*45 : v.count*30).reduce((a, b) => a + b, 0)})
            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );
        fetchApi(`/requests/reqStat?state=approved,reviewed,terminated&start=${isoDate(new Date(new Date().setDate(new Date().getDate()-1)), 0, 0)}&end=${isoDate(new Date(new Date().setDate(new Date().getDate()-1)), 23, 59)}${this.props.role === 4 ? `&utm_source=${this.props.utm_source}` : ''}`)
            .then(({ requests }) => {
                this.setState({ turnoverOfYesterday:  requests.map((v) => v.type === 'dermatologue' ? v.count*45 : v.count*30).reduce((a, b) => a + b, 0)})
            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );

        if (this.props.utm_source) {
            fetchApi(`/requests/analytics?start=${isoDate(null, 0, 0)}&end=${isoDate(null, 23, 59)}&utm_source=${this.props.utm_source}`)
            .then((res) => {
                this.setState({requestsOfTodayPartner: res.requests});
            })
            .catch((err) => console.log(err));
        }

        fetchApi(`/requests/stats/getTimes`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { start: isoDate(this.state.date, 0, 0) } ),
                ...( { end: isoDate(this.state.date, 23, 59) } ),
            })
        })
            .then((res) => {
                this.setState({times: res.stats})
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees utilisateurs: ' + err,
                    'warning'
                )
            });
    }

    onChangeDate () {
        fetchApi(`/requests/stats/getTimes`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { start: isoDate(this.state.date, 0, 0) } ),
                ...( { end: isoDate(this.state.date, 23, 59) } ),
            })
        })
            .then((res) => {
                this.setState({times: res.stats})
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees utilisateurs: ' + err,
                    'warning'
                )
            });
        
    }
    

    render() {
        if (this.props.role && this.props.role < 2) {
            return (
                <div>
                    <Panel>
                        <Panel.Heading>
                            <h6>Home</h6>
                        </Panel.Heading>
                    </Panel>
                </div>
            );
        }
        return (
            <div className="pT-25">
                { this.props.role === 4 ? <PartnerDashBoard source={this.props.utm_source} requests={this.state.requests} requestsOfToday={this.state.requestsOfTodayPartner}/> : 
                this.props.role === 5 ? 
                    (<div className='dashboard'>
                        <Row>
                            <Col>
                                <Panel>
                                    <Panel.Heading>
                                        <h6>Global </h6>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <div className='dashboard-global'>
                                            <div className='dashboard-counter'>
                                                <div>
                                                    <p className='title'>Utilisateurs</p>
                                                    <p> <i className='fa fa-user icon' style={{backgroundColor: colors.user.main}}/> {this.state.usersCount}</p>
                                                    <ReactEcharts
                                                        option={this.getUsersOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>
                                   
                                                <div>
                                                    <p className='title'>Requêtes</p>
                                                    <p> <i className='fa fa-file icon' style={{backgroundColor: colors.request.main}} /> {this.state.countRequest}</p>
                                                    <ReactEcharts
                                                        option={this.getRequestsOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>
                                    
                                                <div>
                                                    <p className='title'>Chiffre d'affaires</p>
                                                    <p> <i className='fa fa-euro-sign icon' style={{backgroundColor: colors.turnover.main}}/> {this.state.requestsCA.map((v) => v.type === 'dermatologue' ? v.count*45 : v.count*30).reduce((a, b) => a + b, 0)}</p>
                                                    <ReactEcharts
                                                        option={this.getCAOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>

                                                <div>
                                                    <p className='title'>Visit</p>
                                                    <p> <i className='fa fa-eye icon' style={{backgroundColor: colors.visit.main}}/> {this.state.visitsCount}</p>
                                                    <ReactEcharts
                                                        option={this.getVisitOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col lg={5} md={5} sm={12} xs={12}>
                                                        <ReactEcharts
                                                            option={this.getRepOptions()}
                                                            style={{ height: '350px', width: '100%' }}
                                                        />
                                                    </Col>

                                                    <Col lg={7} md={7} sm={12} xs={12}>
                                                        <ReactEcharts
                                                            option={this.getDoctorPercent()}
                                                            style={{ height: '350px', width: '100%' }}
                                                        />              
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Panel.Body>
                                </Panel>
                  
                            </Col>
                        </Row>
                    </div>) :
                    (<div className='dashboard'>
                        <Row>
                        {this.state.countWaiting > 30 && (
                            <div style={{ backgroundColor: '#d6ae71', padding: '10px 20px 10px 20px', color: 'white' }}>
                                <b>Consultation en attente supérieur à 30 !</b>
                            </div>
                        )}
                        </Row>
                        <Row>
                            <Panel>
                                <Panel.Heading>
                                    <h6>Compteur {new Date().toISOString().split('T')[0]}</h6>
                                </Panel.Heading>
                                <Panel.Body className='today-counter'>
                                    <CardCount
                                        number={this.state.userOfToday}
                                        dif={this.state.userOfToday - this.state.userOfYesterday}
                                        subtitle="Utilisateurs"
                                        icon="fa fa-user"
                                        background={colors.user.main}
                                    />
                                    <CardCount
                                        number={this.state.requestOfToday}
                                        dif={this.state.requestOfToday - this.state.requestOfYesterday}
                                        subtitle="Requêtes Validés"
                                        icon="fa fa-file"
                                        background={colors.request.main}
                                    />
                                    <CardCount
                                        number={`${this.state.turnoverOfToday}`}
                                        dif={this.state.turnoverOfToday - this.state.turnoverOfYesterday}
                                        subtitle="Chiffre d'affaire"
                                        icon="fa fa-euro-sign"
                                        background={colors.turnover.main}
                                    />
                                    <CardCount
                                        number={this.state.visitOfToday}
                                        dif={this.state.visitOfToday - this.state.visitOfYesterday}
                                        subtitle="Visit"
                                        icon="fa fa-eye"
                                        background={colors.visit.main}
                                    />
                                </Panel.Body>
                            </Panel>
                        </Row>
                        <Row>
                            <Col>
                                <Panel>
                                    <Panel.Heading>
                                        <div style={{display: "flex"}}>
                                            <h6 style={{marginRight: "10px"}}>Plages d'horaires des consultations du </h6>
                                            <input
                                                max={new Date().toISOString().split('T')[0]}
                                              
                                                onChange={e => this.setState({
                                                    date: e.target.value
                                                }, () => {
                                                    this.onChangeDate();
                                                })} 
                                                type="date" 
                                                value={this.state.date}
                                            />
                                            {/* <DateRangePicker onChange={value => this.onChangeDateRange(value)} value={this.state.range} maxDate={new Date()}/> */}
                                        </div>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <Col lg={10} md={10} sm={12} xs={12}>
                                            <ReactEcharts
                                                option={this.getTimes()}
                                                style={{ height: '350px', width: '100%' }}
                                            />              
                                        </Col>
                                    </Panel.Body>
                                </Panel>
                  
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Panel>
                                    <Panel.Heading>
                                        <h6>Global </h6>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <div className='dashboard-global'>
                                            <div className='dashboard-counter'>
                                                <div>
                                                    <p className='title'>Utilisateurs</p>
                                                    <p> <i className='fa fa-user icon' style={{backgroundColor: colors.user.main}}/> {this.state.usersCount}</p>
                                                    <ReactEcharts
                                                        option={this.getUsersOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>
                                   
                                                <div>
                                                    <p className='title'>Requêtes</p>
                                                    <p> <i className='fa fa-file icon' style={{backgroundColor: colors.request.main}} /> {this.state.countRequest}</p>
                                                    <ReactEcharts
                                                        option={this.getRequestsOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>
                                    
                                                <div>
                                                    <p className='title'>Chiffre d'affaires</p>
                                                    <p> <i className='fa fa-euro-sign icon' style={{backgroundColor: colors.turnover.main}}/> {this.state.requestsCA.map((v) => v.type === 'dermatologue' ? v.count*45 : v.count*30).reduce((a, b) => a + b, 0)}</p>
                                                    <ReactEcharts
                                                        option={this.getCAOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>

                                                <div>
                                                    <p className='title'>Visit</p>
                                                    <p> <i className='fa fa-eye icon' style={{backgroundColor: colors.visit.main}}/> {this.state.visitsCount}</p>
                                                    <ReactEcharts
                                                        option={this.getVisitOptions()}
                                                        style={{ height: '250px', width: '100%' }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col lg={5} md={5} sm={12} xs={12}>
                                                        <ReactEcharts
                                                            option={this.getRepOptions()}
                                                            style={{ height: '350px', width: '100%' }}
                                                        />
                                                    </Col>

                                                    <Col lg={7} md={7} sm={12} xs={12}>
                                                        <ReactEcharts
                                                            option={this.getDoctorPercent()}
                                                            style={{ height: '350px', width: '100%' }}
                                                        />              
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Panel.Body>
                                </Panel>
                  
                            </Col>
                        </Row>

                        <Row>
                            <WeeklyCounter />
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}
