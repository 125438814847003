import React, { useEffect, useState } from 'react';

import '../../Assets/Pages/notification.css';
import {  Panel, Row, Col, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactEcharts from 'echarts-for-react';
import isoDate from '../../Services/toIsoDate';
import dateFormat from 'dateformat';
import IconTooltip from '../Ui/IconTooltip';
import { rqState, typeStates } from '../../Constants';
import moment from 'moment';

const sourceAcquisition = [
    { value: 'google', label: 'Google' },
    { value: 'bing', label: 'Bing' },
    { value: 'skycall', label: 'Skycall (sauf Skycall Voice)' },
    { value: 'skycall-voice', label: 'Skycall Voice' },
    { value: 'gescall', label: 'Gescall' },
    { value: 'medifil', label: 'Medifil' },
    { value: 'crechmed', label: 'Crechmed' },
    { value: null, label: 'No source' },
    { value: 'cortel', label: 'Cortel' },
    { value: 'pharmacy', label: 'Pharmacie' },

]

const newReqData = [
    { value: null, label: 'Aucun' },
    { value: 'withNew', label: 'Nouveaux requete apres suivi' },
]

const options1 = [
    { value: null, label: 'Tous' },
    { value: 0, label: 'Guérison' },
    { value: 1, label: 'Aggravation nécessitant une consultation physique' },
    { value: 2, label: 'Hospitalisation' },
    { value: 3, label: 'Autre' }
]

const options2 = [
    { value: null, label: 'Tous' },
    { value: 0, label: 'Allergies' },
    { value: 1, label: 'Intolérance' },
    { value: 2, label: 'Aucun' }
]

const options3 = [
    { value: null, label: 'Tous' },
    { value: true, label: 'Oui' },
    { value: false, label: 'Non' },
]

const TrackingSuivi = (props) => {
    const [range, setRange] = useState([moment().subtract('1', 'months'), new Date()]);
    const [state, setState] = useState([]);
    const [typeState, setTypeState] = useState([]);
    const [requestsData, setRequestsData] = useState([]);
    const [question1, setQuestion1] = useState(null);
    const [question2, setQuestion2] = useState(null);
    const [question3, setQuestion3] = useState(null);
    const [filteredSource, setFilteredSource] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [newReqFilter, setNewReqFilter] = useState(null)


    const setOptions = (valueDatas, nameDatas) => {
        let option = {
            grid: {
                left: 100,
                top: 600,
                right: 0,
                bottom: 0
              },
            tooltip: {
              trigger: 'item',
              formatter: (value) => value.name + ' : ' + (value.value *100).toFixed(2) + '%'
            },
            series: [
                {
                  name: '',
                  type: 'pie',
                  radius: '50%',
                  data: [],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
        };
        if (!valueDatas) {
            return option;
        }
       const datas = valueDatas.map((el, i) => {
        return {value: el, name: nameDatas[i]}
    })
   
        option.series[0].data = datas
       return option;
    };

    const getRequests = () => {
        let startDate = range[0];
        let endDate= range[1];

        setLoading(true)

        fetchApi(`/requests/analytics/suivi?page=${page}${startDate ? `&start=${isoDate(startDate, 0, 0)}` : ''}${endDate ? `&end=${isoDate(endDate, 23, 59)}` : ''}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${filteredSource ? `&utm_source=${filteredSource.value}`: ''}${question1 && question1.value !== null ? `&question1=${question1.value}`: ''}${question2 && question2.value !== null ? `&question2=${question2.value}`: ''}${question3 &&  question3.value !== null ? `&question3=${question3.value}`: ''}`)
            .then(response => {
                const req = response.requests.map((r) => {
                    const userReq = r.userId.request

                    userReq.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

                    let count = 0;
                    let i = 1
                    let j = 0;

                    while (i < userReq.length) {
                        if (userReq[i]) {
                            j++;
                            if (!!userReq[i].postQuestionary) {
                                count = j;
                                break;
                            }
                        }
                        i++;
                    }

                    r.countNew = count

                    return r
                })

                setNewReqFilter(null)
                setRequestsData(req)
                setPage(1)
                setLoading(false)
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
                setLoading(false)
            });
    };

    useEffect(() => {
        const arr = requestsData
        if (newReqFilter) {
            const filter = arr.filter((i) => i.countNew > 0)
            setRequestsData(filter)
        }
        else getRequests()
    }, [newReqFilter])

    useEffect(() => {
        setTotalPage(Math.ceil(requestsData.length / 10))
    }, [requestsData.length])

    useEffect(() => {
        getRequests();
    }, [range])

    const filterSource = (arr, source) => {
        let result;
        if (source === "pharmacy") {
            result = arr.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes(source)).length;
        } else if (source ==="skycall-voice") {
            result = arr.filter(el => (el.utm_source === "skycall" && el.utm_content && el.utm_content.includes("voice")) || el.skycallVoice).length;
        } else if (source === "skycall") {
            result = arr.filter(el => el.utm_source === "skycall" && el.utm_content && !el.utm_content.includes("voice")).length;
        } else {
            result = arr.filter(el => el.utm_source === source).length;
        }
   
        return result;
    }

    const filterRecurrent = (arr, source) => {
        let startDate = range[0];
        let endDate= range[1];

        let result;
        if (source === "pharmacy") {
            result = arr.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes(source)).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        } else if (source ==="skycall-voice") {
            result = arr.filter(el => (el.utm_source === "skycall" && el.utm_content && el.utm_content.includes("voice")) || el.skycallVoice).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        } else if (source === "skycall") {
            result = arr.filter(el => el.utm_source === "skycall" && el.utm_content && !el.utm_content.includes("voice")).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        } else {
            result = arr.filter(el => el.utm_source === source).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        }

        if (source === 'medifil') {
            result = arr.filter(el => el.utm_source === source).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        }
   
        return result;
    }

    const filterPathology = (arr, p) => {
         return arr.filter(el => el.type === p).length;
    }

    const indexOfLastRow = page * 10;
    const indexOfFirstRow = indexOfLastRow - 10;

    const currentRows = requestsData.slice(indexOfFirstRow, indexOfLastRow);

    return (
        <div className="r-analytics">
            <Modal
                show={loading}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="modal-spinner"
            >
                <span className="loader" />
                {loading && <p>Récupération des données en cours...</p>}
            </Modal>
            <Panel>
                <Panel.Heading className='heading'>
                    <h2>Suivi post téléconsultation</h2>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 10 }}>
                        <h6>Compteur du {' '} </h6>
                        <DateRangePicker clearIcon={null}  onChange={value => setRange(value)} value={range} maxDate={new Date()}/>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 10, margin: '10px 0' }}>
                        <Select
                            value={sourceAcquisition.filter(function(option) {
                                return option === filteredSource;
                              })}
                            defaultValue={filteredSource?.value || ''}
                            onChange={(v) => {
                                setFilteredSource(v)
                            }}
                            options={sourceAcquisition}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par source d'acquisition ..." 
                            className='filter-status'
                        />
                        <Select
                            value={state}
                            onChange={(v) => setState(v)}
                            options={rqState}
                            isMulti
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par status ..." 
                            className='filter-status'
                        />
                        <Select
                            value={typeState}
                            onChange={(v) => setTypeState(v)}
                            options={typeStates}
                            isMulti
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par pathologie ..." 
                            className='filter-status'
                        />

                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 10, margin: '10px 0' }}>
                    <Select
                            value={question1}
                            onChange={(v) => setQuestion1(v)}
                            options={options1}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par question 1 ..." 
                            className='filter-status'
                        />
                        <Select
                            value={question2}
                            onChange={(v) => setQuestion2(v)}
                            options={options2}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par question 2 ..." 
                            className='filter-status'
                        />
                        <Select
                            value={question3}
                            onChange={(v) => setQuestion3(v)}
                            options={options3}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par question 3 ..." 
                            className='filter-status'
                        />
                        <button className='submit-button' onClick={() => {
                            getRequests();
                        }}>Chercher</button>
                    </div>
                    <div>
                        <Select
                            value={newReqFilter}
                            onChange={(v) => {
                                setNewReqFilter(v.value ? v : null)
                            }}
                            options={newReqData}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par nouveau requete ..." 
                            className='filter-status'
                        />
                    </div>
                </Panel.Heading>
                <Panel.Body>
                    
                {
                    requestsData.length > 0 &&
                    <>
                       <div style={{ display: 'flex', gap: 15, marginTop: 10 }}>

                            <p>
                            Liste des utilisateurs (Page <strong>{page}</strong> /{' '}
                                {totalPage})
                            </p>

                            <div>
                                {page > 1 && (
                                    <IconTooltip
                                        icon="fa fa-chevron-left"
                                        text="Page précédente"
                                        onClick={() => setPage(page - 1)}
                                    />
                                )}
                                {page < totalPage && (
                                    <IconTooltip
                                        icon="fa fa-chevron-right"
                                        text="Page suivante"
                                        
                                        onClick={() => setPage(page + 1)}
                                    />
                                )}
                            </div>

                        </div>
                         <div className="table-responsive">
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>Utilisateur</th>
                                        <th>Email</th>
                                        <th>Date de creation</th>
                                        <th>Type</th>
                                        <th>Etat</th>
                                        <th>Docteur</th>
                                        <th>Vu par docteur</th>
                                        <th>Nb de nouveau requete</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows.map((r) => {
                                        return (
                                            <tr key={r._id}>               
                                                <td> {r.userId?.FName} {r.userId?.LName}</td>
                                                <td>{r.userId?.email}</td>
                                                <td>{dateFormat(r.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                <td>{r.type}</td>
                                                <td>{r.notOnlineReason ? 'Indisponible à distance' : r.state === "canceled" && r.canceledByDoctor === true ? "Annulé par médecin" : rqState.find(el=> el.value === r.state)?.label}</td>
                                                <td>
                                                    {r.doctorId
                                                        ? r.doctorId.FName + ' ' + r.doctorId.LName
                                                        : ''}
                                                </td>
                                                <td>{r.postQuestionaryIsRead ? 'Oui' : 'Non'}</td>
                                                <td>{r.countNew}</td>
                                                <td>
                                                    <IconTooltip
                                                        icon="fas fa-file-medical"
                                                        text="Details"
                                                        onClick={() =>
                                                            props.history.push(
                                                                '/requests/detail/' + r._id
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                       </>
                    }
                    {
                        requestsData.length > 0 ? 
                        <div>
                            <h3>Total: {requestsData.length}</h3>
                            <h3>Par pathologie</h3>
                            <Row>
                                <Col lg={7} md={6} sm={12} xs={12}>
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0 analytic-table">
                                        <thead>
                                            <th>Pathologie</th>
                                            <th>Nombre de requêtes</th>
                                            <th>Pourcentage</th>
                                        </thead>
                                        <tbody>
                                            {
                                                typeStates.filter(el => filterPathology(requestsData, el.value) > 0).map(type => <tr key={type.value}>
                                                    <td>{type.label}</td>
                                                    <td>{filterPathology(requestsData, type.value)}</td>
                                                    <td>{((filterPathology(requestsData, type.value) / requestsData.length) * 100).toFixed(2)} %</td>
                                            
                                                </tr>)
                                            }                            
                                        </tbody>
                                    </table>

                                </div>
                                </Col>
                                <Col lg={5} md={6} sm={12} xs={12}>
                                    <ReactEcharts
                                        option={setOptions(
                                            typeStates.filter(el => filterPathology(requestsData, el.value) > 0).map(type => (filterPathology(requestsData, type.value) / requestsData.length)),
                                            typeStates.filter(el => filterPathology(requestsData, el.value) > 0).map(type => type.label)
                                        )}
                                        style={{ height: '250px', width: '100%' }}
                                    />  
                                </Col>
                            </Row>
                            <h3>Par source d'acquisition</h3>
                            <Row>
                                <Col lg={7} md={6} sm={12} xs={12}>
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0 analytic-table">
                                        <thead>
                                            <th>Source d'acquisition</th>
                                            <th>Nombre de requêtes</th>
                                            <th>Pourcentage</th>
                                            <th>Récurrence</th>
                                            <th>Pourcentage</th>
                                        </thead>
                                        <tbody>
                                            {
                                                sourceAcquisition.filter(s => filterSource(requestsData, s.value) > 0).sort(function(a,b) {
                                                    return filterSource(requestsData, b.value) - filterSource(requestsData, a.value)
                                                }).map(source => <tr key={source.value}>
                                                    <td>{source.label}</td>
                                                    <td>{filterSource(requestsData, source.value)}</td>
                                                    <td>{((filterSource(requestsData, source.value) / requestsData.length) * 100).toFixed(2)} %</td>
                                                    <td>{filterRecurrent(requestsData, source.value)}</td>
                                                    <td>{((filterRecurrent(requestsData, source.value)/filterSource(requestsData, source.value))*100).toFixed(2) || 0} % </td>
                                                </tr>)
                                            }                            
                                        </tbody>
                                    </table>
                                </div>

                                </Col>
                                <Col lg={5} md={6} sm={12} xs={12}>
                                    <ReactEcharts
                                        option={setOptions(
                                            sourceAcquisition.filter(s => filterSource(requestsData, s.value) > 0).map(source => (filterSource(requestsData, source.value) / requestsData.length)),
                                            sourceAcquisition.filter(s => filterSource(requestsData, s.value) > 0).map(source => source.label)
                                        )}
                                        style={{ height: '250px', width: '100%' }}
                                    />  
                                </Col>
                            </Row>
                        </div>
                        :<div><h4>Pas de requête</h4></div>}

                    
                </Panel.Body>
            </Panel>
        </div>
    );
};

export default TrackingSuivi;
