import React from 'react';
import { useState } from 'react';

import '../../Assets/Pages/campagneSdr.css';

import { fetchApi } from '../../Network/NetworkService';
import toIsoDate from '../../Services/toIsoDate';
import { notif } from '../Ui/Notification';
import { rqState } from '../../Constants';

const typeStates = [
    { value: '', label: 'Tous' },
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste', label: 'generaliste' },
    { value: 'gynecologie', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];

const userOpt = [
    { value: 'new', label: 'Nouveau' },
    { value: 'recurrent', label: 'Récurrent' },
]

const CampagneSdr = () => {
    const [option, setOption] =  useState('');
    const [mailOption, setmailOption] =  useState('');
    const [reqState, setReqState] = useState('waiting');
    const [reqType, setReqType] = useState('')
    const [userType, setUserType] = useState('new');
    const [value, setValue] = useState({
        email: '',
        phone: '',
        subj: '',
        msg: ''
    });
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);

    const reset = () => {
        setValue({
            email: '',
            phone: '',
            msg: '',
            subj: ''
        })
    }


    const send = () => {
        setLoading(true);
        fetchApi(`/campagne-sdr/sendMessage/${option}${mailOption ? `/${mailOption}` : ''}`, {
            method: 'POST',
            body: JSON.stringify({
                ...value,
                ...(mailOption === 'perRequest' ? { state: reqState, date: toIsoDate(date, 0, 1), type: reqType } : {}),
                ...(mailOption === 'perUser' ? { userType, date: toIsoDate(date, 0, 1) } : {})
            })
        })
        .then(() => {
            notif.addNotification(
                'Success',
                'Message envoyé !',
                'success'
            )
            setLoading(false);
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Erreur d\'envoie !',
                'warning'
            )
            setLoading(false);
        }
        );
    }

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }

    return (
        <div className='campagne-sdr'>
            <p>Campagne SDR</p>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <span>Envoyer un :</span>
                <select onChange={(e) => {
                    setOption(e.target.value);
                    setmailOption('email');
                    reset();
                }}>
                    <option value="">choisir</option>
                    <option value="email">email</option>
                    <option value="sms">sms</option>
                </select>
            </div>
            {option === 'email' ? (
                <>
                    <div className='sdr-block'>
                        <select onChange={(e) => {
                            setmailOption(e.target.value);
                        }}>
                            <option value="email">par email</option>
                            <option value="perRequest">par requete</option>
                            <option value="perUser">par type d'user</option>
                        </select>
                        {mailOption === 'perRequest' && (
                            <>
                                <select onChange={(e) => {
                                    setReqState(e.target.value);
                                }}>

                                    {rqState.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <select onChange={(e) => {
                                    setReqType(e.target.value);
                                }}>

                                    {typeStates.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <div>
                                    Debut : <input value={date} max={new Date().toISOString().split('T')[0]} onChange={(e) => setDate(e.target.value)} type="date" name="date" />
                                </div>
                            </>
                        )}
                        {mailOption === 'perUser' && (
                            <>
                                <select onChange={(e) => {
                                    setUserType(e.target.value);
                                }}>

                                    {userOpt.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <div>
                                    Date d'inscription : <input value={date} max={new Date().toISOString().split('T')[0]} onChange={(e) => setDate(e.target.value)} type="date" name="date" />
                                </div>
                            </>
                        )}
                    </div>
                    {mailOption === 'email' && (
                        <input value={value.email} onChange={handleChange} name='email' type="text" className='input-search' placeholder='Email' />
                    )}
                    <input value={value.subj} onChange={handleChange} name='subj' type="text" className='input-search' placeholder='Sujet' />
                    <textarea value={value.msg} onChange={handleChange} name='msg' type="text" className='input-search' placeholder='Contenu' />
                    <button onClick={send} disabled={(mailOption === 'email' && !value.email) || !value.subj || !value.msg || loading}>Envoyer</button>
                </>
            ) : option === 'sms' && (
                <>
                    <input value={value.phone} onChange={handleChange} name='phone' type="text" className='input-search' placeholder='Téléphone' />
                    <textarea value={value.msg} onChange={handleChange} name='msg' type="text" className='input-search' placeholder='Contenu' />
                    <button onClick={send} disabled={!value.phone || !value.msg || loading}>Envoyer</button>
                </>
            )}
        </div>
    )
}

export default CampagneSdr;