import { io as Socket} from 'socket.io-client';

export const endpoint = 'https://adminback.feeli.io';
// export const endpoint = 'http://localhost:7777';
// Equivalences

export const socket = Socket(endpoint, {
    transports: ['websocket']
});

export const requestState = ['incomplete', 'waiting', 'approved', 'toCall', 'reviewed', 'terminated', 'canceled', 'refunded'];

const refs = [
    'erectileDysfunction',
    'cystitis',
    'asthma',
    'hemorrhoids',
    'oralHerpes',
    'pregnancyTest',
    'mstTest',
    'travelKitAndVaccination'
];
const tran = [
    'Dysfonction Erectile',
    'Cystite',
    'Asthme',
    'Hemorroïdes',
    'Herpes buccal',
    'Contraception',
    'Test MST',
    'Kit de voyage et vaccination'
];
const colors = [
    '#46bcb9',
    '#ff4ca4',
    '#02ce26',
    '#46bcb9',
    '#1f75b1',
    'rgb(254, 187, 82)',
    'rgb(0, 205, 172)',
    'rgb(9, 32, 63)'
];

export const getPathologyFromCode = code => {
    const i = refs.indexOf(code);
    if (i !== -1) {
        return tran[i];
    }

    return code;
};

export const getCodeFromPathology = pathology => {
    const i = tran.indexOf(pathology);
    if (i !== -1) {
        return refs[i];
    }

    return pathology;
};

export const getColorFromCode = code => {
    const i = refs.indexOf(code);
    if (i !== -1) {
        return colors[i];
    }

    return '#c23531';
};

export const toCamelCase = phrase =>
    phrase
        .toLowerCase()
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

export const rqState = [
    { value: 'incomplete', label: 'Incomplete' },
    { value: 'approved', label: 'Approuvé' },
    { value: 'reviewed', label: 'Examiné' },
    { value: 'waiting', label: 'En Attente' },
    { value: 'notOnline', label: 'Impossible à distance' },
    { value: 'terminated', label: 'Terminé' },
    { value: 'refunded', label: 'Remboursé' },
    { value: 'toCall', label: 'A appeler' },
    { value: 'canceled', label: 'Annulé' },
    { value: 'canceledByDoctor', label: 'Annulé par le docteur'  }
]

export const comparisonArr = [
    {value: 'yesterday', label: "Jour précédent"},
    {value: 'last-month', label: "Mois précédent"},
    {value: 'last-year', label: "Année précédente"},
    {value: 'personalized', label: "Personnalisé"},
];

export const typeStates = [
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste', label: 'generaliste' },
    { value: 'gynecologie', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'kit-voyage-et-vaccination', label: 'kit-voyage-et-vaccination' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'renouvellement-ordonnance', label: 'renouvellement-ordonnance' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];

export const priorityStates = [
    { value: null, label: 'Tous les types' },
    { value: true, label: 'Urgent' },
    { value: false, label: 'Non urgent' },
];